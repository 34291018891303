import { createGlobalStyle } from "styled-components"

export const theme = {
  font: {
    sans: "Karla, Source Sans Pro, Roboto, sans-serif",
    serif: "Arvo, sans",
    headings: "Fjalla One, sans-serif",
    monospace: '"Ubuntu Mono", monospace',
  },
  colours: {
    common: {
      black: "#000000",
      white: "#FFFFFF",
      grey: "#EEEEEE",
    },
    primary: {
      light: "#ca3b32",
      main: "#f78d1d",
      dark: "#231f20",
      dark2: "#1c1c1c",
      contrastText: "#ffffff",
    },
  },
  animations: {
    button: "box-shadow 0.3s ease",
    link: "color 0.5s ease",
    background: "background-color 0.2s ease-in-out",
    backgroundimage: "background 2.0s ease-in-out",
    nav: "top .15s ease-in-out",
    imgtint: "rgb(247 141 29 / 30%)"
  },
  shadows: { 
    shadow1: "0px 5px 20px rgba(30,30,31, 0.05)",
    shadow2: "0 .5rem 1rem rgba(0,0,0,.15)",
    background: "rgb(29 9 34 / 0.7)",
    backgroundfull: "rgb(29 9 34 / 1.0)",
  },
}

export const GlobalStyle = createGlobalStyle`

  //global resets
  
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.font.sans};
    font-size: 16px;
    color: '#1a202c';
  }
  body {
    line-height: 1.2;
    letter-spacing: 0;
    font-size: 1.4em;
    font-weight: 200;
    font-family: ${({ theme }) => theme.font.sans};
    // background-color: ${({ theme }) => theme.colours.common.grey};
    // background: url(/images/body_bg.jpg) #000 repeat-x;

    //     background-color: #FBAB7E;
    // background-image: linear-gradient(45deg, #FBAB7E 0%, #f78d1d 100%);
    //180
    // background: rgb(0,0,0);
    // background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(94,77,58,1) 25%, rgba(247,141,29,1) 73%);
    background-COLOR: #FFF;

    border-top: solid ${({ theme }) => theme.colours.primary.main} 15px;


    color: ${({ theme }) => theme.colours.text};

  }
  a:hover {
    color: ${({ theme }) => theme.colours.primary.dark};
  }
  p {
    line-height: 1.2;
  }
  h2, h3 {
    font-family: ${({ theme }) => theme.font.headings};
    font-weight: 400;
    text-transform: uppercase;
  }

  h4 {
    font-style: italic;
    font-weight: 100;
    font-family: roboto;
    margin-bottom: 1em;
  }

  .mainBG {
    // background: url(/images/main_bg.jpg) no-repeat;
  }

  //navigation

  .navbar-collapse {
    justify-content: flex-end;
  }

  .navContainer {
    min-height: 75px;
  }

  .navbar {
    width: 100%;
    padding: 0px;
    min-height: 75px;

    //navscroll
    background-color: ${({ theme }) => theme.colours.common.white} !important;
    // box-shadow: ${({ theme }) => theme.shadows.shadow2};
    // border-bottom: solid ${({ theme }) => theme.colours.primary.main} 15px;
  }

  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28247, 141, 29, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
    
  
  .navbar-light .navbar-toggler {
    border-color: rgba(247,141,29);
  }

  .navbar-light .navbar-brand{
    flex: auto;
    top: 0;
    background: white;
    padding: 0;
    margin-top: 10px;
    height: 75px;
    width: 50%;
    overflow: visible;
  }

  .navbar-light .navbar-brand .gatsby-image-wrapper, .navbar-light .navbar-brand .gatsby-image-wrapper div {
    height: 75px;
  }

  .inner .navbar-light .navbar-brand {
    top: -25px;
  }

  .navbar-light .navbar-nav {
    text-transform: uppercase;
    margin-right: 0;
  }

  .navbar-light .navbar-nav .nav-link, .navPhone {
    font-weight: 400;
    font-family: ${({ theme }) => theme.font.headings};
    color: ${({ theme }) => theme.colours.primary.main};
    transition: ${({ theme }) => theme.animations.link};
    text-align: center;
  }
  
  .navPhone {
    color: ${({ theme }) => theme.colours.primary.main};
    margin-right: 10px;
  }
  .navPhone:hover {
    color: ${({ theme }) => theme.colours.primary.main};
  }

  a {
    color: ${({ theme }) => theme.colours.primary.main};
    text-decoration: none;
  }

  .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0,0,0,.7)
  }

  .navbar-light .navbar-nav .item {
    padding: 0 5px;
  }

  //masthead

  .heroRow {
    // background: ${({ theme }) => theme.animations.imgtint};
  }
  .heroContainer {
    margin: 0 auto;
  }
  .heroContainer, .hero {
    max-height: 60vh;
    // min-height: 70vh;
  }
  .heroContainer, .hero img {
    z-index: -1;
  }
  .hero, .navContainer {
    margin: 0 auto;
  }

  .footerRow {
    font-size: 1.2rem;
    margin-top: 100px;
  }

 .footerRow .logo {
    text-align: center;
  }

  .footerCopy {
    text-align: center;
    margin: 50px 0 10px 0;
    font-size: 1.2rem;
  }

  b, strong {
    font-weight: bold;
  }

  //contact

  .form-group {
    margin: 15px 0px;
  }

  .custom-select {
    margin-left: 10px;
  }

  //lightbox

  .SRLAutoplayButton, .SRLThumbnailsButton, .SRLDownloadButton, .SRLExpandButton {
    display: none !important;
  }

//gallery

.card {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 10px;
    -moz-column-gap: 10px;
    column-gap: 10px;
}

//form

form, iframe {
  padding: 20px;
}

.g-recaptcha iframe {
  padding: 0px;
}


// timeline

.timeline {
  width: 100%
}

.timeline.timeline-one .timeline-item {
  position: relative;
  width: 50%;
  margin-left: 50%;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  text-align: left
}

@media (max-width:575.98px) {
  .timeline.timeline-one .timeline-item {
      width: 100%;
      margin-left: 0
  }
}

.timeline.timeline-one .timeline-item img {
  margin-bottom: 1.25rem
}

.timeline.timeline-one .timeline-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  margin-top: .425rem;
  margin-left: -.5rem;
  border: .0625rem solid #dce6f3;
  border-radius: 50%
}

.timeline.timeline-one .timeline-item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: -.1875rem;
  width: .0625rem;
  margin-top: 1.875rem;
  margin-left: -.0625rem;
  background-color: #dce6f3
}

.timeline.timeline-one .timeline-item:nth-child(2n+1) {
  margin-left: 0;
  padding-right: 1.5rem;
  padding-left: 0;
  text-align: right
}

@media (max-width:575.98px) {
  .timeline.timeline-one .timeline-item:nth-child(2n+1) {
      padding-right: 0;
      padding-left: 1.5rem;
      text-align: left
  }
}

.timeline.timeline-one .timeline-item:nth-child(2n+1):after,
.timeline.timeline-one .timeline-item:nth-child(2n+1):before {
  right: 0;
  left: auto
}

@media (max-width:575.98px) {

  .timeline.timeline-one .timeline-item:nth-child(2n+1):after,
  .timeline.timeline-one .timeline-item:nth-child(2n+1):before {
      right: auto;
      left: 0
  }
}

.timeline.timeline-one .timeline-item:nth-child(2n+1):before {
  margin-right: -.5rem
}

.timeline.timeline-one .timeline-item:nth-child(2n+1):after {
  margin-right: -.0625rem
}

.timeline.timeline-one.dark .timeline-item:before {
  border: .0625rem solid #b7c3d2
}

.timeline.timeline-one.dark .timeline-item:after {
  background-color: #b7c3d2
}

.footerRow .logo {
  margin-bottom: 30px;
}


//services

.row .subtitle {
  // color: #f78d1d;
  color: #000;
  margin-bottom: 25px;
  font-size: 1.5rem;
}

  //SM
  @media (min-width: 576px) { 
    .aboutBg {
      // margin-top: -100px;
      margin-top: 20px;
      border-top: solid 5px ${({ theme }) => theme.colours.primary.main};
      min-height: 350px;
    }

    .navbar-light .navbar-brand{
      padding: 20px;
      height: auto;
      width: auto;
    }

    
    .navbar-light .navbar-brand .gatsby-image-wrapper, .navbar-light .navbar-brand .gatsby-image-wrapper div {
      height: auto;
    }

    .footerRow .logo {
      margin-bottom: 10px;
    }

    }

  }

  //MD
  @media (min-width: 768px) { 
    
    .aboutBg {
      min-height: 300px;
    }

    .serviceImg {
      img {
        border-bottom: solid 4px #f78d1d;
      }
    }
  }
  
  //LG
  @media (min-width: 992px) { 
    .aboutBg {
      min-height: 500px;
    }
  
  }

  //XL
  @media (min-width: 1200px) { 
    .aboutBg {
      min-height: 460px;
    }

  }
  //XXL
  @media (min-width: 1400px) { 

  }
 
`